import React from "react";

const style = {
    container:{
        width: "30%"
    },
    title:{
        backgroundColor: "#ff4d1d", 
        width: 150, 
        fontSize: "0.9375rem", 
        paddingLeft: 10, 
        fontWeight: "bold"
    },
    text:{
        color: "#ffffff", 
        textAlign: "left", 
        paddingRight: 30, 
        marginTop: 30, 
        fontSize: "0.9375rem"
    }
}

const ParagraphSection = (props) => {

    const {title, text} = props;

    return(
        <div style={style.container}>
            <div style={style.title}>
                {title} 
            </div>
            <div style={style.text}>
                {text}
            </div>
        </div>
    );
}
export default ParagraphSection
