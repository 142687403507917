import React from "react";

const style = {
  percent_bottom: {
    width: "100%",
    height: 110,
    textAlign: "center",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: 22,
    paddingTop: 40,
    color: "#000",
  },
  sevirety_bottom: {
    width: "100%",
    height: 30,
    textAlign: "center",
    color: "#ffffff",
  },
  bottom_box: {
    width: 110,
    height: 140,
    float: "left",
    marginLeft: 0,
  },
};

const ChartSevirityBox = (props) => {
  const { color, value, label } = props;

  return (
    <div style={style.bottom_box}>
      <div style={{ ...style.percent_bottom, backgroundColor: color }}>
        {value === undefined ? "" : `${value}%`}
      </div>
      <div style={style.sevirety_bottom}>{label}</div>
    </div>
  );
};

export default ChartSevirityBox;
