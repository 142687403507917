import React, { useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import { composeInitialProps, useTranslation } from "react-i18next";
import ExportIcon from "../images/icons/export.svg";
import MasterPlanLogo from "../images/master-plan/masterplan-top.png";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ParagraphSection from "../components/layouts/masterPlan/paragraph_section";
import ChartSevirityBox from "../components/layouts/masterPlan/chart-sevirity-box";
import Layout from "../components/layout";
import UserRoute from "../components/routes/userRoute";
import axios from "axios";
import Moment from "moment";
import { createPdfFromDom } from "../components/utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, addSubProject } from "../store/ducks/general";

// Component's css
const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    marginLeft: "0%",
  },

  // Header CSS
  header: {
    export_button: {
      border: "none",
      color: "#FFFFFF",
      cursor: "pointer",
    },
    top_container: {
      paddingTop: "3%",
      paddingRight: "5%",
      display: "flex",
      justifyContent: "end",
    },
    buttom_container: {
      paddingTop: "1%",
      display: "flex",
      width: "100%",
      height: "2%",
    },
    masterplan_container: {
      backgroundColor: "#FF4D1D",
      width: 900,
      height: "190px",
      float: "left",
      paddingLeft: 150,
      paddingTop: 31,
    },
    masterplan_logo: {
      float: "left",
      width: "8rem",
    },
    masterplan_text_container: {
      width: 400,
      float: "left",
      height: "150px",
      paddingTop: 20,
      paddingLeft: 40,
    },
    masterplan_text_date: {
      color: "#FFFFFF",
      fontSize: "0.9375rem",
    },
    masterplan_text_title: {
      color: "#FFFFFF",
      fontSize: "1.875rem",
      fontWeight: "600",
    },
    masterplan_report_data_container: {
      width: 600,
      height: "190px",
      float: "left",
      color: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 20,
    },
    masterplan_report_data_row: {
      fontSize: "0.9375rem",
    },
  },

  // Chart CSS
  chart: {
    container: {
      marginTop: 75,
      marginLeft: "10%",
      width: "80%",
      height: 610,
      zIndex: 3,
    },
    chart_container: {
      width: 1000,
      height: "600px",
      position: "absolute",
      display: "flex",
      justifyContent: "center",
    },
    top_border_box: {
      width: 1000,
      height: 302,
      zIndex: 2,
      position: "absolute",
      backgroundColor: "#393939",
      border: "2px solid #FFFFFF",
      borderBottom: "none",
    },
    bottom_border_box: {
      width: 1000,
      height: 100,
      zIndex: 2,
      position: "absolute",
      marginTop: 303,
      backgroundColor: "#212121",
      border: "2px solid #FFFFFF",
    },
    boxes_container: {
      width: 1000,
      height: 110,
      zIndex: 2,
      position: "absolute",
      marginTop: 350,
    },
    bottom_container: {
      width: 875,
      margin: "auto",
      height: 110,
      display: "flex",
      justifyContent: "space-around",
    },
  },

  // Paragraphs CSS
  paragraphs: {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    first_paragraph: {
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: 1100,
      },
      title: {
        color: "#FFFFFF",
        fontSize: "1.375rem",
        fontWeight: "600",
        paddingBottom: 60,
      },
      section: {
        display: "flex",
        justifyContent: "space-between",
      },
      border: {
        borderBottom: "2px solid #ffffff",
        paddingTop: 50,
        width: 1100,
      },
    },
    second_paragraph: {
      title: {
        color: "#FFFFFF",
        fontSize: "1.375rem",
        paddingBottom: 40,
        fontWeight: "600",
        paddingTop: 40,
        width: 1100,
      },
      text_container: {
        width: 1100,
      },
      text: {
        fontSize: "0.9375rem",
        color: "#ffff",
        width: 600,
      },
    },
  },

  // Footer CSS
  footer: {
    container: {
      backgroundColor: "#FF4D1D",
      height: "70px",
      paddingTop: 25,
      marginTop: 120,
      display: "flex",
      justifyContent: "center",
    },
    text: {
      color: "#ffff",
      width: 620,
    },
  },
};

const MasterPlan = () => {
  const [clientName, setClientName] = useState("");
  const [siaReport, setSiaReport] = useState();

  const dispatch = useDispatch();

  const loginValues = useSelector((state) => state.general.loginValues);
  const projectData = useSelector((state) => state.general.projectData);

  const goToSubProject = (subProjectData) => {
    if (loginValues.subProject !== subProjectData.sub_project) {
      dispatch(addSubProject(subProjectData.sub_project));
      window.localStorage.setItem("auth", JSON.stringify({ ...loginValues }));
      window.localStorage.setItem(
        "subProjectData",
        JSON.stringify(subProjectData)
      );
      navigate("../u-collect-dashboard/dashboard");
    }
  };

  // Initial configuration and plugins
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

  useEffect(() => {
    if (clientName === "" && Object.keys(projectData).length > 0) {
      try {
        axios
          .get(`/masterplan`, {
            params: {
              clientId: loginValues.typeId,
              subProject: projectData.sub_project,
              role: loginValues.role,
            },
          })
          .then((res) => {
            if (res) {
              setClientName(res.data.clientName);
              setSiaReport(res.data.masterplanData);
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [projectData]);

  const createPdf = () => {
    dispatch(setLoading(true));

    const fileName =
      projectData.title +
      "_Masterplan_Report_" +
      Moment().format("DD_MM_YYYY_hh_mm_ss");

    // The properties for each dom element
    const htmlClass = {
      id: "pdf_export",
      rotation: 0,
      lMargin: 0,
      tMargin: 0,
      width: 211,
      height: 297,
    };

    // html Extraction
    const html = window.document.getElementsByName(htmlClass.id)[0];

    html.children[1].style.marginLeft = "-130px";

    createPdfFromDom(html, fileName, htmlClass, "masterplan", "");

    setTimeout(() => {
      html.children[1].style.marginLeft = "255px";
      dispatch(setLoading(false));
    }, 1000);

    return true;
  };

  // Options configuration for the chart
  const options = {
    layout: {
      padding: {
        bottom: 296,
      },
    },
    plugins: {
      datalabels: {
        color: "black",
        font: { size: 19 },
        formatter: (value) => {
          return value + "%";
        },
      },
    },
  };

  let chartData =
    siaReport?.score_1 === undefined
      ? []
      : [
          siaReport?.score_1,
          siaReport?.score_2,
          siaReport?.score_3,
          siaReport?.score_4,
          siaReport?.score_5,
        ];

  // Data configuration for the chart
  const data = {
    datasets: [
      {
        data: chartData,
        rotation: -90,
        circumference: 180,
        backgroundColor: [
          "#FFFD78",
          "#FCCB5E",
          "#FF8C4C",
          "#E2674E",
          "#DD381D",
        ],
      },
    ],
  };

  // Translation component
  const { t } = useTranslation();

  return (
    <UserRoute>
      <Layout goToSubProject={goToSubProject}>
        <div style={style.container}>
          {/* HEADER */}
          <div style={style.header.top_container}>
            <div
              style={style.header.export_button}
              onClick={() => {
                createPdf();
              }}
            >
              <ExportIcon alt="Icon that represents file exporting" />{" "}
              {t("export")}
            </div>
          </div>
          <div name="pdf_export">
            <div style={style.header.buttom_container}>
              <div style={style.header.masterplan_container}>
                <img
                  style={style.header.masterplan_logo}
                  src={MasterPlanLogo}
                />
                <div style={style.header.masterplan_text_container}>
                  <span style={style.header.masterplan_text_date}>
                    {t("master-plan-title")} -<span> {projectData.date}</span>
                  </span>
                  <br />
                  <span style={style.header.masterplan_text_title}>
                    <span>{projectData.title}</span>
                  </span>
                </div>
              </div>

              <div style={style.header.masterplan_report_data_container}>
                <span style={style.header.masterplan_report_data_row}>
                  {t("prepared-header")}
                  <b>
                    <span>{clientName}</span>
                  </b>
                </span>
                <br />
                <span style={style.header.masterplan_report_data_row}>
                  {t("date-header")}
                  <b>
                    <span>{siaReport?.date_prepared}</span>
                  </b>
                </span>
                <br />
                <span style={style.header.masterplan_report_data_row}>
                  {t("survey-header")}
                  <b>
                    <span>{siaReport?.date_from} </span>–
                    <span> {siaReport?.date_to}</span>
                  </b>
                </span>
                <br />
              </div>
            </div>

            {/* CHART */}
            <div style={style.chart.container}>
              <div name="chart" style={style.chart.chart_container}>
                <Doughnut data={data} options={options} style={{ zIndex: 3 }} />
                <div style={style.chart.bottom_border_box}></div>
                <div style={style.chart.top_border_box}></div>

                <div style={style.chart.boxes_container}>
                  <div style={style.chart.bottom_container}>
                    <ChartSevirityBox
                      color="#FFFD78"
                      value={siaReport?.score_1}
                      label="Low"
                    />
                    <ChartSevirityBox
                      color="#FCCB5E"
                      value={siaReport?.score_2}
                      label="Low-Medium"
                    />
                    <ChartSevirityBox
                      color="#FF8C4C"
                      value={siaReport?.score_3}
                      label="Medium"
                    />
                    <ChartSevirityBox
                      color="#E2674E"
                      value={siaReport?.score_4}
                      label="Medium-High"
                    />
                    <ChartSevirityBox
                      color="#DD381D"
                      value={siaReport?.score_5}
                      label="High"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* PARAGRAPHS */}
            <div style={style.paragraphs.container}>
              {/* PARAGRAPH 1 */}
              <div style={style.paragraphs.first_paragraph.container}>
                <div style={style.paragraphs.first_paragraph.title}>
                  {t("recommend-action")}
                </div>
                <div style={style.paragraphs.first_paragraph.section}>
                  <ParagraphSection
                    title={t("planning")}
                    text={t("for-use-gis")}
                  />
                  <ParagraphSection
                    title={t("maintenance")}
                    text={t("for-use-by")}
                  />
                  <ParagraphSection
                    title={t("operations")}
                    text={t("for-determination")}
                  />
                </div>
              </div>

              <div style={style.paragraphs.first_paragraph.border}></div>

              {/* PARAGRAPH 2 */}
              <div style={style.paragraphs.second_paragraph.title}>
                {t("how-this-works")}
              </div>

              <div style={style.paragraphs.second_paragraph.text_container}>
                <div style={style.paragraphs.second_paragraph.text}>
                  {t("this-product")}
                </div>
              </div>
            </div>

            {/* FOOTER */}
            <div style={style.footer.container}>
              <div style={style.footer.text}>{t("copyright")}</div>
            </div>
          </div>
        </div>
      </Layout>
    </UserRoute>
  );
};

export default MasterPlan;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["master plan", "navigation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

/* <title>Master Plan Reports</title>
	 	<meta charset="utf-8">
	 	<meta name="viewport" content="width=device-width, initial-scale=1">
	 	<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
	 	<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
	 	<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
	 	<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

	 	<script src="jspdf.debug.js"></script>
	 	<script src="dom-to-image.js"></script>

	 	<link rel="stylesheet" href="style.css">
	 	<script src="canvasjs/canvasjs.min.js"> </script>
	 	<script src="script.js"> </script>
	 </head>

	 <body> */

{
  /* 					
			<div id="pdf_5">
				
			</div>
			
			<div id="pdf_6">
				
			</div>
		</div> */
}
